import React, { useEffect } from 'react';
import LiColud from '../../assets/img/il-cloud.svg';
import LiHumidity from '../../assets/img/il-humidity.png';
import LiWaterneds from '../../assets/img/il-waterneeds.png';
import useWeather from "../../hooks/useWeather";
import { weatherProvider } from "../../providers/weatherProvider";
import Moment from "moment";
import useGlobal from "../../store/index";
import "./WeatherComponent.css";

export const WeatherComponent = (props) => {
    const [weather, setWeather] = useWeather();
    const [mapCenterPosition] = useGlobal(state => state.mapCenterPosition);
    const { currentWeather } = weatherProvider();

    useEffect(() => {
        if (mapCenterPosition) {
            currentWeather({
                lat: mapCenterPosition.lat,
                lng: mapCenterPosition.lng
            }).then(r => {
                setWeather(r);
            });
        }
    }, [mapCenterPosition]);

    if (!weather || weather.isLoading) {
        return null;
    }

    return (
        weather.hasError ? (
            <div className="col-lg-3 card wather_block center-content">
                <h3>Weather is not available</h3>
            </div>
        ) : (
            <div className="col-lg-3 card wather_block">
                <div className="wather_top">
                    <div className="wather_top_left">
                        <img src={LiColud} className="il-cloud" alt="Cloud Icon" />
                        <span>Now</span>
                    </div>
                    <div className="wather_top_right text-right">
                        <p>{weather.data.temperature}°C / {weather.data.windspeed} km/h</p>
                    </div>
                </div>
                <div className="wather_content">
                    <div className="wather_content_1">
                        <div className="wather_content_1_left">
                            <img src={LiHumidity} className="il-humidity" alt="Humidity Icon" />
                            <span>Humidity (% RH)</span>
                        </div>
                        <div className="wather_content_1_right text-right">
                            <p>{weather.data.humidity || "N/A"}%</p>
                        </div>
                    </div>
                    <div className="wather_content_2">
                        <div className="wather_content_2_left">
                            <img src={LiWaterneds} className="il-waterneeds" alt="Water Needs Icon" />
                            <span>Water Needs (1/m3)</span>
                        </div>
                        <div className="wather_content_2_right text-right">
                            <p>6.7 L</p>
                        </div>
                    </div>
                </div>
                <div className="wather_footer">
                    <p>Data updated at: {Moment().format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>
            </div>
        )
    );
};

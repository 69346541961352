export const weatherProvider = () => {
    const _baseUrl = "https://api.open-meteo.com/v1/forecast";

    // Функция для загрузки данных с сервера
    const load = async (url) => {
        const response = await fetch(url);
        if (response.ok) {
            return response.json();
        }
        throw { error: response.status, message: response.statusText };
    };

    // Получение текущей погоды
    const currentWeather = async (position) => {
        // Проверяем, переданы ли корректные координаты
        if (!position || typeof position.lat !== "number" || typeof position.lng !== "number") {
            throw new Error("Invalid position object. Expected format: { lat: number, lng: number }");
        }

        // Запрос текущей погоды и влажности
        const url = `${_baseUrl}?latitude=${position.lat}&longitude=${position.lng}&current_weather=true&hourly=relative_humidity_2m`;
        try {
            const weather = await load(url);

            // Извлечение текущей влажности
            const currentHourIndex = new Date().getHours();
            const humidity = weather.hourly.relative_humidity_2m[currentHourIndex];

            // Возвращаем текущую погоду вместе с влажностью
            return {
                isLoading: false,
                data: {
                    ...weather.current_weather,
                    humidity,
                },
            };
        } catch (err) {
            console.error(err);
            return {
                isLoading: false,
                data: {},
                hasError: true,
            };
        }
    };

    return { currentWeather };
};

import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HCMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import Gauge from "highcharts/modules/solid-gauge";
import useGlobal from "../../store/index";

HCMore(Highcharts);
SolidGauge(Highcharts);
Gauge(Highcharts);

const options = {
  chart: {
    type: 'gauge',
    plotBackgroundColor: null, // Убираем фон
    plotBackgroundImage: null, // Убираем изображение фона
    plotBorderWidth: 0,
    plotShadow: false,
    width: 140,
    height: 140
  },

  title: {
    text: ''
  },

  pane: {
    startAngle: -150,
    endAngle: 150,
    background: [{
      backgroundColor: null, // Убираем фон
      borderWidth: 0,
      outerRadius: '109%'
    }, {
      backgroundColor: null, // Убираем фон
      borderWidth: 1,
      outerRadius: '107%'
    }, {
      // default background
    }, {
      backgroundColor: '#DDD',
      borderWidth: 0,
      outerRadius: '105%',
      innerRadius: '103%'
    }]
  },

  yAxis: {
    min: 0,
    max: 10,
    minorTickInterval: 'auto',
    minorTickWidth: 1,
    minorTickLength: 10,
    minorTickPosition: 'inside',
    minorTickColor: '#666',
    tickPixelInterval: 30,
    tickWidth: 2,
    tickPosition: 'inside',
    tickLength: 10,
    tickColor: '#666',
    labels: {
      step: 2,
      rotation: 'auto'
    },
    title: {
      text: ''
    },
    plotBands: [{
      from: 0,
      to: 6,
      color: '#55BF3B' // green
    }, {
      from: 6,
      to: 8,
      color: '#DDDF0D' // yellow
    }, {
      from: 8,
      to: 10,
      color: '#DF5353' // red
    }]
  },

  series: [{
    name: 'Preasure',
    dial: {
      radius: "50%"
    },
    data: [0],
    tooltip: {
      valueSuffix: ' Bar'
    }
  }]
};

export const GaugeChart = (props) => {
  const chartComponent = useRef(null);
  const [pumps] = useGlobal(state => state.pumps);

  const callBack = (chart) => {
    setInterval(function () {
      if (chart.axes) { // not destroyed
        var point = chart.series[0].points[0];
        let pump = pumps.data.find(x => x.id === props.id);
        point.update(pump.pressure);
      }
    }, 3000);
  };

  useEffect(() => {
    callBack(chartComponent.current.chart);
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponent}
    />
  );
};

import React from "react";
import PropTypes from "prop-types";
import LiManomenter from '../../assets/img/il-manomenter.png';
import './PumpComponent.css';

import { GaugeChart } from "../GaugeChart/GaugeChartComponent";

export const PumpComponent = ({ title, turnedOn, pressure, id }) => {
    const isOn = turnedOn === '1';

    return (
        <div className={`col pump ${isOn ? "block-on" : "block-off"}`}>
            <p>{title}</p>
            <span className="on_off">{isOn ? "ON" : "OFF"}</span>
            <div className="manomenter-block">
                <GaugeChart pressure={pressure} id={id} style={{ background: "none" }} />
            </div>
        </div>
    );
};

// Типизация свойств (PropTypes)
PumpComponent.propTypes = {
    title: PropTypes.string.isRequired,    // Название насоса
    turnedOn: PropTypes.string.isRequired, // Статус насоса ('1' или '0')
    pressure: PropTypes.number.isRequired, // Давление
    id: PropTypes.oneOfType([              // ID насоса
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
};
